import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Loader } from "rsuite";
import "./App.css";
import "./styles/Nrjwtr.css";
import { NrjRequire } from "./utilities/NrjRequire";
import Dashboard from "./app/dshbrd/Dashboard";
import { setCmpId, setUsrId } from "./utilities/utilities";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import SwitchLayout from "./components/SwitchLayout";
const LzCbwtfSrch = React.lazy(() => import("./app/brds/CbwtfSrch"));
const LzDashBoardCBWTLst = React.lazy(
  () => import("./app/dshbrd/DashBoardCBWTLst")
);
const LzDashBoardClrRw = React.lazy(
  () => import("./app/dshbrd/DashBoardClrRw")
);
const LzIndiaHeatMapValue = React.lazy(() => import('./app/Reports/IndiaHeatMapValues'));

const LzNrjLgn = React.lazy(() => import("./login/NrjLogin"));
const LzAllStateReport = React.lazy(()=> import("./app/AnnualRpt/AllStateReport"));
const LzAllAnnlReport = React.lazy(()=> import("./app/AnnualRpt/AllAnnlReport"));

const LzChangePassword = React.lazy(() => import("./app/login/ChangePasswod"));
const LzLstReg = React.lazy(() => import("./app/hcf/LstReg"));
const LzWstCntIncnrt = React.lazy(() => import("./app/hcf/WstCndIncnrt"));
const LzCityHcfList = React.lazy(() => import("./app/vb/MIS/CityHcfList"));
const LzRgdHcfCount = React.lazy(() => import("./app/vb/MIS/RgdHcfCount"));
const LzMstr = React.lazy(() => import("./app/brds/Mstrs"));
const LzDshBrd = React.lazy(() => import("./app/dshbrd/Dashbrd"));
const LzRgDrct = React.lazy(() => import("./app/brds/RgnDirctr"));
const LzRgnDirtLst = React.lazy(() => import("./app/brds/RgnDirctrLst"));
const LzSttDircctrLst = React.lazy(() => import("./app/brds/SttDirctrLst"));
const LzStateBrd = React.lazy(() => import("./app/brds/StateBrd"));
const LzPncStts = React.lazy(() => import("./app/admn/bckofc/PncStts"));
const LzRgnOffcLst = React.lazy(() => import("./app/brds/RgnOffcLst"));
const LzReginalOffce = React.lazy(() => import("./app/brds/ReginalOffce"));
const LzPccList = React.lazy(() => import("./app/brds/PccBrdLst"));
const LzPccBrd = React.lazy(() => import("./app/brds/PccBrd"));
const LzCpcbHo = React.lazy(() => import("./app/brds/CpchHoPg"));
const LzDashBoard = React.lazy(() => import("./app/dshbrd/Dashboard"));
const LzSttsAll = React.lazy(() => import("./app/brds/SttsAll"));
const LzAnnlRpt = React.lazy(() => import("./app/AnnualRpt/AnnlRpt"));
const LzAnnlMisc = React.lazy(() => import("./app/AnnualRpt/AnnlMisc"));

const LzAnnlEqp = React.lazy(() => import("./app/AnnualRpt/AnnlEqp"));
const LzAnnlWstStrg = React.lazy(() => import("./app/AnnualRpt/AnnlWstStrg"));
const LzAnnlWstWt = React.lazy(() => import("./app/AnnualRpt/AnnlWstWt"));
const LzMisRpt = React.lazy(() => import("./app/Reports/MisReports"));
const LzHcfNmbrVisited = React.lazy(
  () => import("./app/Reports/MisHcfNmbrVisited")
);
const LzHcfNonVisited = React.lazy(
  () => import("./app/Reports/MisHcfNonVisited")
);
const LzSrchHcf = React.lazy(() => import("./app/Reports/SrchHcf"));
const LzDashBoardRgHCF = React.lazy(
  () => import("./app/dshbrd/DashBoardRgHcf")
);
const LzNewReg = React.lazy(() => import("./app/admn/cbwtf/NewReg"));
const LzDashBrdBagWise = React.lazy(
  () => import("./app/dshbrd/DashBrdBagWise")
);
const LzDashGraph = React.lazy(() => import("./app/dshbrd/DashBoardClrGrph"));
const LzDashGraphVb = React.lazy(() => import("./app/vb/DashBoardClrGrph"));

const LzHCFDisplay = React.lazy(() => import("./app/Reports/HcfDisplay"));
const LzListCbwtfWstData = React.lazy(
  () => import("./app/Reports/ListCbwtfWstData")
);
const LzHCFCnt = React.lazy(() => import("./app/Reports/HcfCount"));
const LzGridDisply = React.lazy(() => import("./app/Reports/GridDisplay"));
const LzCbwtfLstRep = React.lazy(() => import("./app/Reports/CbwtfLst"));
const LzAnnualRepA = React.lazy(() => import("./app/Reports/Annual/AnnualA"));
const LzAnnualRepB = React.lazy(() => import("./app/Reports/Annual/AnnualB"));
const LzAnlauthB = React.lazy(() => import("./app/Reports/Annual/AnlauthB"));
const LzAnlCbwtC = React.lazy(() => import("./app/Reports/Annual/anlCbwtC"));
const LzAnlCbwtD = React.lazy(() => import("./app/Reports/Annual/anlCbwtD"));
const LzAnlCbwtE = React.lazy(() => import("./app/Reports/Annual/anlCbwtE"));
const LzAnlHcfA = React.lazy(() => import("./app/Reports/Annual/anlHcfA"));
const LzBigBag = React.lazy(() => import("./app/Reports/BigBag"));
const LzTrackingBmw = React.lazy(() => import("./app/Reports/TrakingBmw"));
const LzWrongWstBgCbwtf = React.lazy(() => import("./app/Reports/WrongWstBgCbwtf"));
const LzListHCF = React.lazy(() => import("./app/Reports/ListHCF"));
const LzListHCFvb = React.lazy(() => import("./app/vb/Reports/ListHCFvb"));
const LzClrCbwtfWst = React.lazy(() => import("./app/Reports/ClrCbwtfWst"));
const LzStt_Annlauth = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annlauth"));
const LzStt_Annldstr = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annldstr"));
const LzStt_Annlcptv = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annlcptv"));
const LzAnnCbwtf = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annlcbwtf"));
const LzAnnlFrst = React.lazy(() => import("./app/AnnualRpt/State/Stt_Annlfrst"));
const LzNrjForgotPassword = React.lazy(() => import("./login/NrjForgotPassword"));
const LzMisHCFNotVstd = React.lazy(() => import("./app/Reports/MisHCFNotVstd"));
const LzMisBagcount = React.lazy(() => import("./app/Reports/MisBagCountLbl"));
const LzSerialNumber = React.lazy(() => import("./app/Reports/MisSerialNumber"));
const LzMisBagcntwthGeo = React.lazy(() => import("./app/Reports/MisBagCntwthGeo"));
const LzBagCntPrHr = React.lazy(() => import("./app/dshbrd/BagCntPrHr"));
const LzBagCntPrHrGrid = React.lazy(() => import("./app/dshbrd/bagCnthrGrid"));
const LzBhuvanMap = React.lazy(() => import("./app/Reports/BhuvanMap"));
const LzGoogleMap = React.lazy(() => import("./app/Reports/GoogleMap"));
const LzHelpPage = React.lazy(() => import("./app/Reports/HelpPage"));
const LzHcfvstoday = React.lazy(() => import("./app/Reports/MisHcfvstoday"));
const LzClrHcfWst = React.lazy(() => import("./app/Reports/ClrHcfWst"));
const LzHcfMonthly = React.lazy(() => import("./app/Reports/MonthlyHCFsts"));
const LzHcf_Wstbg = React.lazy(() => import("./app/Reports/HCF_Wstbg"));
const LzSrch_hcf = React.lazy(() => import("./app/Reports/Srch_hcf"));
const LzHcfctgCnt = React.lazy(() => import("./app/dshbrd/HcfCtgCnt"));
const LzHcfctgCntGrid = React.lazy(() => import("./app/dshbrd/HcfCtgCntGrid"));
const LzVbTodayHcf = React.lazy(() => import("./app/vb/MIS/MisHcfvstoday"));
const LzVbMonthHcf = React.lazy(() => import("./app/vb/MIS/MonthlyHCFsts"));
const LzBrdActvSmry = React.lazy(() => import("./app/vb/Reports/BrdActvSmry"));

const LzBeddedRatio = React.lazy(() => import("./app/dshbrd/BeddedRatio"));
const LzWstbgOdd = React.lazy(() => import("./app/Reports/WstbgOdd"));

const LzFind_HCF =React.lazy(() => import("./app/Reports/Find_HCF"));
const LzWstbgid =React.lazy(() => import("./app/Reports/WastebagId"));
const Lzcbwtfdlyrep =React.lazy(() => import("./app/Reports/cbwtfdlyrep"));
const LzAcknowledgmentHcf = React.lazy(() => import("./app/Reports/MisAcknowledgmentHcf"));
const LzDisplayDataCard = React.lazy(() => import("./app/Reports/DisplayDataCard"));
const LzDailyReports = React.lazy(() => import("./app/Reports/DailyReport/DailyReports"));
const LzDailySummary = React.lazy(() => import("./app/Reports/DailyReport/DailySummary"));
const LzDailySummaryFactory = React.lazy(() => import("./app/Reports/DailyReport/DailySummaryFactory"));
const LzDailySummaryFactoryNotScn = React.lazy(() => import("./app/Reports/DailyReport/DailySummaryFactoryNotScn"));
const LzSummaryHcf = React.lazy(() => import("./app/Reports/DailyReport/SummaryHcf"));
const LzBagCountChart = React.lazy(() => import("./app/dshbrd/BagCountChart"));
const LzBagWeightChart = React.lazy(() => import("./app/dshbrd/DashboardChartView"));
const LzBagHcfChart = React.lazy(() => import("./app/dshbrd/BagHcfChart"));

function App() {
  document.title = "CPCB";
  useEffect(() => {
    const storedUsrId = sessionStorage.getItem("usrid") || "1";
    const storedCmpId = sessionStorage.getItem("cmpid") || "1";

    if (window.location.hostname === "localhost") {
      setCmpId(storedCmpId);
      setUsrId(storedUsrId);
    } else {
      const storedUsrId = sessionStorage.getItem("usrid");
      const storedCmpId = sessionStorage.getItem("cmpid");

      if (storedCmpId && storedUsrId) {
        setCmpId(storedCmpId);
        setUsrId(storedUsrId);
      } else {

      }
    }
  }, []);

  const openHelpPage = () => {
    const pdfUrl = "pdfs/" + window.location.pathname.replace("/", "") + ".pdf";
    window.open(pdfUrl, "_blank");
  };




  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <Routes>
        <Route
          index
          // path="/login"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzNrjLgn />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/forgotPassword"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzNrjForgotPassword />
            </React.Suspense>
          }
        ></Route>

        <Route
          path="/login"
          element={
            <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
              <LzNrjLgn />
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/"
          element={
            <SwitchLayout />
          }>
          <Route
            path="/mapIndia"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                {/* <LzIndiaHeatMap></LzIndiaHeatMap> */}
                <LzIndiaHeatMapValue></LzIndiaHeatMapValue>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/cbwtfdspl"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCbwtfLstRep></LzCbwtfLstRep>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcflist"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzListHCF
                    path={"hcflist"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}
                    cols={[
                      {
                        field: "cbwtfid",
                        hidden: true,
                        width: 50,
                        headerName: "S No",
                      },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cntprsn",
                        width: 180,
                        headerName: "Contact Person",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "nobd",
                        width: 120,
                        headerName: "No of Beds",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                    ]}
                  ></LzListHCF>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfbd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzListHCF
                    path={"hcfbd"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}
                    cols={[
                      {
                        field: "cbwtfid",
                        hidden: true,
                        width: 50,
                        headerName: "S No",
                      },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cntprsn",
                        width: 180,
                        headerName: "Contact Person",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "nobd",
                        width: 120,
                        headerName: "No of Beds",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                    ]}
                  ></LzListHCF>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfbdvb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzListHCFvb
                    path={"hcfbdvb"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}
                    cols={[
                      {
                        field: "cbwtfid",
                        hidden: true,
                        width: 50,
                        headerName: "S No",
                      },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cntprsn",
                        width: 180,
                        headerName: "Contact Person",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "nobd",
                        width: 120,
                        headerName: "No of Beds",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                    ]}
                  ></LzListHCFvb>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/spcb_cbwtf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnCbwtf></LzAnnCbwtf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/allStateReport" 
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAllStateReport></LzAllStateReport>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_frst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlFrst></LzAnnlFrst>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_auth"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStt_Annlauth />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_dstr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStt_Annldstr />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfWasteData"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfvstoday></LzHcfvstoday>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bhuvanmap"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBhuvanMap />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/googlemap"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzGoogleMap />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/spcb_hcfcptv"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStt_Annlcptv />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfnbd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzListHCF
                    path={"hcfnbd"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}
                    cols={[
                      {
                        field: "cbwtfid",
                        hidden: true,
                        width: 50,
                        headerName: "S No",
                      },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cntprsn",
                        width: 150,
                        headerName: "Contact Person",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                      },
                      {
                        field: "hcftyp",
                        width: 70,
                        headerName: "HCF Type",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                    ]}
                  ></LzListHCF>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcflstgrd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzGridDisply
                    path={"hcfregtdy"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}
                    cols={[
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcod",
                        width: 150,
                        headerName: "SPCB Code",
                        tooltipField: "tphcf",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        width: 200,
                        headerName: "City",
                      },
                      {
                        field: "cntprsn",
                        width: 200,
                        headerName: "Contact Person",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "phn",
                        width: 150,
                        headerName: "Mobile",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcftyp",
                        width: 70,
                        headerName: "HCF Type",
                      },
                      {
                        field: "ltt",
                        width: 150,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 150,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                      {
                        field: "stt",
                        width: 100,
                        headerName: "State",
                      },
                      {
                        field: "eml",
                        width: 200,
                        headerName: "Email",
                      },
                      {
                        field: "nobd",
                        width: 150,
                        headerName: "No of beds",
                      },
                      {
                        field: "bluscl",
                        width: 150,
                        headerName: "Blutooth Scale",
                        hide: true,
                      },
                      {
                        field: "andrapp",
                        width: 150,
                        headerName: "Android App",
                        hide: true,
                      },
                      {
                        field: "rgd",
                        width: 150,
                        headerName: "Regional Directorate",
                      },
                      {
                        field: "tphcf",
                        width: 150,
                        hide: true,
                        headerName: "",
                      },
                    ]}
                  ></LzGridDisply>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/srchhcf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSrchHcf
                    path={"hcfregtdy"}
                    inputData={{ lvl: "CPCB", who: "", dtno: "0" }}
                    cols={[
                      {
                        field: "cbwtfid",
                        hidden: true,
                        width: 50,
                        headerName: "S No",
                      },
                      {
                        field: "hcfnm",
                        width: 320,
                        headerName: "HCF",
                        // tooltipField : "tphcf",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcod",
                        width: 100,
                        headerName: "SPCB Code",
                        tooltipField: "tphcf",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "cty",
                        width: 100,
                        headerName: "City",
                      },
                      {
                        field: "cntprsn",
                        width: 150,
                        headerName: "Contact Person",
                      },
                      {
                        field: "phn",
                        width: 120,
                        headerName: "Mobile",
                      },
                      {
                        field: "hcftyp",
                        width: 70,
                        headerName: "HCF Type",
                      },
                      {
                        field: "ltt",
                        width: 100,
                        headerName: "Latitude",
                      },
                      {
                        field: "lgt",
                        width: 100,
                        headerName: "Longtitude",
                      },
                      {
                        field: "addra",
                        width: 150,
                        headerName: "Address I",
                      },
                      {
                        field: "addrb",
                        width: 150,
                        headerName: "Address II",
                      },
                      {
                        field: "addrc",
                        width: 150,
                        headerName: "Address III",
                      },
                      {
                        field: "tphcf",
                        width: 150,
                        hide: true,
                        headerName: "",
                      },
                    ]}
                  ></LzSrchHcf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/dshnw"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDashBoardClrRw />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/dshnwgrph"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDashGraph />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfcbwtf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHCFCnt
                    groupBy={"cbwtfid"}
                    mypage={1}
                    cols={[
                      // {
                      //   field: "srno",
                      //   hidden: true,
                      //   width: 40,
                      //   headerName: "SNo",
                      // },
                      // {
                      //   field: "cbwtfid",
                      //   hidden: true,
                      //   width: 100,
                      //   headerName: "",
                      // },
                      {
                        field: "cbwtfnm",
                        width: 370,
                        headerName: "CBWTF",
                        tooltipField: "tphcf",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "hcfcount",
                        width: 180,
                        headerName: "Health Care Facility",
                        tooltipField: "tphcf",
                      },
                      {
                        field: "beds",
                        width: 120,
                        headerName: "Total Beds",
                      },
                      {
                        field: "bedded",
                        width: 120,
                        headerName: "Bedded HCF",
                        tooltipField: "tpbd",
                      },
                      {
                        field: "nonbedded",
                        width: 150,
                        headerName: "Non Bedded HCF",
                        tooltipField: "tpnobd",
                      },
                      {
                        field: "stt",
                        width: 90,
                        headerName: "State",
                      },
                      {
                        field: "rgd",
                        width: 130,
                        headerName: "RD",
                      },
                      {
                        field: "tphcf",
                        width: 150,
                        hide: true,
                        headerName: "",
                      },
                      {
                        field: "tpbd",
                        width: 150,
                        hide: true,
                        headerName: "",
                      },
                      {
                        field: "tpnobd",
                        width: 150,
                        hide: true,
                        headerName: "",
                      },
                    ]}
                  ></LzHCFCnt>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfrgd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHCFCnt
                    groupBy={"rgd"}
                    mypage={2}
                    cols={[
                      {
                        field: "idsr",
                        hidden: true,
                        width: 100,
                        headerName: "S No",
                      },
                      {
                        field: "_id",
                        width: 400,
                        headerName: "RD",
                      },
                      {
                        field: "bedded",
                        width: 220,
                        headerName: "Bedded HCF ",
                      },
                      {
                        field: "nonbedded",
                        width: 100,
                        headerName: "Non Bedded HCF",
                      },
                      {
                        field: "hcfcount",
                        width: 250,
                        headerName: "Health Care Facility",
                      },
                      {
                        field: "beds",
                        width: 220,
                        headerName: "Total Beds",
                      },
                    ]}
                  ></LzHCFCnt>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfstt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHCFCnt
                    groupBy={"stt"}
                    mypage={3}
                    cols={[
                      {
                        field: "idsr",
                        hidden: true,
                        width: 100,
                        headerName: "S No",
                      },
                      {
                        field: "_id",
                        width: 400,
                        headerName: "SCPB",
                      },
                      {
                        field: "bedded",
                        width: 200,
                        headerName: "Bedded HCF ",
                      },
                      {
                        field: "nonbedded",
                        width: 190,
                        headerName: "Non Bedded HCF",
                      },
                      {
                        field: "hcfcount",
                        width: 200,
                        headerName: "Health Care Facility",
                      },
                      {
                        field: "beds",
                        width: 200,
                        headerName: "Total Beds",
                      },
                    ]}
                  ></LzHCFCnt>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="trckbmw"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzTrackingBmw />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/ListWrngHCFCode"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzWrongWstBgCbwtf />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/lstwst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzDashBoardCBWTLst />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bgws"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzDashBrdBagWise></LzDashBrdBagWise>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/real"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <Dashboard></Dashboard>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfdspl"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHCFDisplay></LzHCFDisplay>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/wstbgs"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzListCbwtfWstData />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cntr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCpcbHo />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/changePwd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzChangePassword />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/dshmn"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDashBoard />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/dshcbwtf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDashBoardRgHCF />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcflst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <NrjRequire>
                    <LzLstReg />
                  </NrjRequire>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/monthlyStatus"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <NrjRequire>
                    <LzHcfMonthly />
                  </NrjRequire>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          
          <Route
            path="/regdts"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWstCntIncnrt />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cityHcfSearch"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCityHcfList />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/rgdHcfSearch"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzRgdHcfCount />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/dsgn"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzMstr Label="Designation" Db="shrtrm" Typr="841" />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/mcty"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzMstr Label="City" Db="shrtrm" Typr="398" />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/dshb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <LzDshBrd></LzDshBrd>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/rgnd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzRgDrct />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/rgndlst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzRgnDirtLst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/sttdlst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSttDircctrLst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/stt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzStateBrd />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/pnclst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzPncStts />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/roflst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzRgnOffcLst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/pcclst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzPccList />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/pcc"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzPccBrd />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/rgnofc"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzReginalOffce />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          
          <Route
            path="/clrCbwtfWst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzClrCbwtfWst />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/sttall"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSttsAll />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/nwcbwtf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzNewReg />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/CbwtfSrch"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCbwtfSrch ctg={"cbwtf"}></LzCbwtfSrch>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/HcfSrch"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzCbwtfSrch ctg={"hcf"}></LzCbwtfSrch>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annualRepA"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnualRepA></LzAnnualRepA>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annualRepB"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnualRepB></LzAnnualRepB>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/anlauthB"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnlauthB></LzAnlauthB>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/anlCbwtC"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnlCbwtC></LzAnlCbwtC>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/anlCbwtD"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnlCbwtD></LzAnlCbwtD>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/anlCbwtE"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnlCbwtE></LzAnlCbwtE>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/anlHcfA"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnlHcfA></LzAnlHcfA>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/allAnnlRpt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  {/* <LzAnnlRpt /> */}
                  <LzAllAnnlReport></LzAllAnnlReport>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annlRpt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlRpt /> 
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annlMisc"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlMisc />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annlEqp"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlEqp />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annlWstStrg"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlWstStrg />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/annlWstWt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAnnlWstWt />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/misRpt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzMisRpt />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfNmbrVisited"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfNmbrVisited />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfnotvst"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzMisHCFNotVstd></LzMisHCFNotVstd>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfNonVisited"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfNonVisited></LzHcfNonVisited>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/bagCntPrHr"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagCntPrHr></LzBagCntPrHr>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagCntPrHrGrid"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagCntPrHrGrid></LzBagCntPrHrGrid>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/bigBag"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBigBag></LzBigBag>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagcntwthLbl"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzMisBagcount></LzMisBagcount>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagcntwthGeo"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzMisBagcntwthGeo></LzMisBagcntwthGeo>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/serialNumber"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSerialNumber></LzSerialNumber>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/displayDataCard"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDisplayDataCard></LzDisplayDataCard>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
           <Route
            path="/bagCntChrt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagCountChart></LzBagCountChart>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagWtChrt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagWeightChart></LzBagWeightChart>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/bagHcfChrt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBagHcfChart></LzBagHcfChart>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          
           <Route
            path="/acknowledgmentHcf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzAcknowledgmentHcf></LzAcknowledgmentHcf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/beddedRatio"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBeddedRatio />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/WstbgOdd"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWstbgOdd />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>


          <Route
            path="/hcfCtgCnt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfctgCnt></LzHcfctgCnt>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfCtgCntGrid"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcfctgCntGrid></LzHcfctgCntGrid>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfMonthlyvb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzVbMonthHcf></LzVbMonthHcf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfWasteDatavb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzVbTodayHcf></LzVbTodayHcf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

          <Route
            path="/helpPage"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHelpPage></LzHelpPage>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          
          <Route
            path="/hcf_wstbg"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzHcf_Wstbg></LzHcf_Wstbg>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/srch_hcf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSrch_hcf></LzSrch_hcf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/find_hcf"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzFind_HCF></LzFind_HCF>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/wstbgid"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzWstbgid></LzWstbgid>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/cbwtfdlyrep"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <Lzcbwtfdlyrep></Lzcbwtfdlyrep>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfwstbg"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzClrHcfWst></LzClrHcfWst>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>


          <Route
            path="/hcfMonthlyvb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzVbMonthHcf></LzVbMonthHcf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/hcfWasteDatavb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzVbTodayHcf></LzVbTodayHcf>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/dashboardvb"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDashGraphVb />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          
          <Route
            path="/dailyReport"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDailyReports />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/dailysummary"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDailySummary />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/dailysummaryfactory"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDailySummaryFactory hideComp = {true} />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
              path="/dailysummaryfactoryComp"
              element={
                <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                  <NrjRequire>
                    <LzDailySummaryFactory hideComp ={false}/>
                  </NrjRequire>
                </React.Suspense>
              }
            ></Route>
          <Route
            path="/dailysummaryfactorynotscn"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzDailySummaryFactoryNotScn />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/dailySummaryRoute"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzSummaryHcf />
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>



          <Route
            path="/actvrpt"
            element={
              <React.Suspense fallback={<Loader size="lg" content="Medium" />}>
                <NrjRequire>
                  <LzBrdActvSmry

                    cols={[
                      {
                        field: "cbwtfid",
                        hide: true,
                        width: 50,
                        headerName: "S No",
                      },
                      {
                        field: "cbwtfnm",
                        width: 200,
                        headerName: "CBWTF",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "state",
                        width: 150,
                        headerName: "State",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "rgd",
                        width: 200,
                        headerName: "Regional Directorate",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "tdyhcf",
                        width: 150,
                        headerName: "HCF Registered",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "tdybags",
                        width: 180,
                        headerName: "Waste Bags Recoeved",
                        filter: "agTextColumnFilter",
                      },
                      {
                        field: "route",
                        width: 120,
                        headerName: "Active Vehicles",
                      }
                    ]}
                  ></LzBrdActvSmry>
                </NrjRequire>
              </React.Suspense>
            }
          ></Route>

        </Route>


      </Routes>

      <Tooltip title="Help" arrow>
        <IconButton
          color="primary"
          onClick={openHelpPage}
          sx={{
            position: "fixed",
            bottom: 40,
            right: 40,
            backgroundColor: "#007BFF",
            "&:hover": {
              backgroundColor: "#0056b3", // Change color on hover
            },
          }}
        >
          <HelpOutlineIcon style={{ color: "white" }} />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default React.memo(App);
