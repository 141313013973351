import React, { useEffect } from 'react'
import { useState } from 'react';
import { Outlet } from 'react-router';
import { useEffectOnce } from 'react-use';
import Sidebar from '../app/vb/sidebar';
import Topbar from '../app/vb/topbar';

import { getApplicationVersion } from '../utilities/utilities';

const SwitchLayout = () => {
    const [pageTitle, setPageTitles] = useState("Dashboard")
    const applicationOne: string = getApplicationVersion();
    const dropdownOne = [
        { name: 'Live Waste Bag Collection', path: "/trckbmw", description: "Real Time Waste Collection (From Midnight)" },
        { name: 'CBWTF List', path: "/cbwtfdspl", description: "COMMON BIOMEDICAL WASTE TREATMENT FACITLIY" },
        { name: 'CBWTF Info', path: "/displayDataCard", description:"CBWTF Information" },
        { name: 'HCF List', path: "/hcflstgrd", description: "List of Health Care Facility" },
        { name: 'HCF - CBWTF', path: "/hcfcbwtf", description: "Common Biomedical Waste Facility" },
        { name: 'Waste Bags', path: "/wstbgs", description: "List of Waste Bags" },
        { name: 'List of Wrong HCF code', path: "/ListWrngHCFCode", description: "List of Wrong HCF Code" },
        { name: 'Cbwtf Daily Report', path: "/cbwtfdlyrep", description: "Daily Report of Cbwtf" },
        { name: 'Annual Form (CBWTF)', path: "/allAnnlRpt", description: "Annual Report : CBWTFs" },
        { name: 'State Annual Report', path: "/allStateReport", description: "State Annual Reports" },
        { name: 'Annual Report', path: "/annualRepA", description: "" },
        { name: 'Bag Count Dashboard', path: "/bagCntChrt", description: "Bag count from bedded / Non bedded Hospital" },
        { name: 'Bag Weight Dashboard ', path: "/bagWtChrt", description: "Bag weight from bedded / Non bedded Hospital" },
        { name: 'Hcf Dashboard', path: "/bagHcfChrt", description: "Hcf Dashboard" },

    ]

    const dropdownTwo = [
        { name: 'CPCB', path: "/cntr", description: "Details of Head Office" },
        { name: 'RD', path: "/rgndlst", description: "List of Regional Directorate" },
        { name: 'SPCB', path: "/sttdlst", description: "List Of State Pollution Board" },
        { name: 'All State Boards', path: "/sttall", description: "All State Boards" }
    ]

    const dropdownThree = [
        { name: 'HCF Category Count (Chart View)', path: "/hcfCtgCnt", description: "HCF Category Count " },
        { name: 'HCF Category Count', path: "/hcfCtgCntGrid", description: "HCF Category Count " },
        //{ name: 'HCF Visited', path: "/hcfNmbrVisited", description: "Count of Visited HCF" },
        { name: 'HCF Visited/Non Visited', path: "/hcfNonVisited", description: "Count of HCF's Visited/Non-Visited" },
        // { name: 'HCF Not Visited', path: "/hcfnotvst", description: "List of Not Visited HCF" },
        { name: 'Bag Count Per Hour (Chart View)', path: "/bagCntPrHr", description: "Bag Count Per Hour" },
        { name: 'Bag Count Per Hour', path: "/bagCntPrHrGrid", description: "Bag Count Per Hour" },
        { name: 'Bags Above 30 Kg', path: "/bigBag", description: "List of Waste Bag above 30 Kg" },
        { name: 'Waste Bag GeoLocation', path: "/bagcntwthGeo", description: "Summary of Waste Bags with and without Location Data" },
        { name: 'Waste Bag Serial Number', path: "/serialNumber", description: "Summary of Waste Bags With and Without Serial Number" },
        { name: 'Waste Bag Label No', path: "/bagcntwthLbl", description: "Summary of Waste Bag with and without Label Count" },
        { name: 'Waste Bag Odd Time Bags', path: "/WstbgOdd", description: "Waste Bag Received At Odd Time i.e. From Midnight To 8 AM  and After 8 PM" },
        { name: 'Hcf Acknowledgment', path: "/acknowledgmentHcf", description: "Count of Hcf who's Acknowledgment Recevied And Not Recevied" },

        
        //{ name: 'Monthly HCF Status', path: "/monthlyStatus", description: "Monthly status of Health Care Facility" },
    ]

    const dropdownFour = [
        { name: 'Acivity on Portal', path: "/actvrpt", description: "Activity on the Biomedical Waste Portal" },
        { name: 'map/India', path: '/mapIndia', description: " Heat Map of India" },
        { name: 'Bedded Approximately  Ratio', path: '/beddedRatio', description: "Ratio with respect to actual and expected value" },
    ]
    const dropdownFive = [
        { name: 'Search HCF', path: "/cityHcfSearch", description: "Search HCF in City" },
        { name: 'Waste Bag', path: "/hcf_wstbg", description: "Search Waste Bags" },
        { name: 'Find HCF', path: "/Find_HCF", description: "Search HCF" },
        { name: 'Waste Bag By ID', path: "/wstbgid", description: "Search Waste Bag By ID" },
        
        { name: 'Search Health Care Facility', path: "/srch_hcf", description: "Health Care Facility" },
        { name: 'Count HCF', path: "/rgdHcfSearch", description: " HCF in Regional Directorate" },
        // { name: 'PNU ', path: "/hcfWasteData", description: "Count of HCF visited by cbwtf" },
    ]
    const dropdownSix = [
        { name: 'Daily Report: Detailed', path: "/dailyReport", description: "Waste Collection Report: Daily Detailed" },
        { name: 'Daily Report: Summary', path: "/dailysummary", description: "Waste Collection Report: Daily Summary" },
        { name: 'Daily Report: Route Wise', path: "/dailySummaryRoute", description: "Daily Summary: Route Wise" },
        { name: 'Daily Summary: Factory (Comparison)', path: "/dailysummaryfactoryComp", description: "Waste Bags Scanned at HCF / Factory (Comparison)" },
        
        { name: 'Daily Summary: Factory', path: "/dailysummaryfactory", description: "Waste Bags Scanned at Factory" },
        { name: 'Daily Summary: Factory Not Scan', path: "/dailysummaryfactorynotscn", description: "Waste Bags Scanned at Factory Not Scan" },
    ]
    const otherpage = [
        { path: "/spcb_frst", description: "State Annual Report: Details" },
        { path: "/spcb_cbwtf", description: "State Annual Report: CBWTF" },
        { path: "/spcb_auth", description: "State Annual Report: Authorize " },
        { path: "/spcb_dstr", description: "State Annual Report:  District " },
        { path: "/spcb_hcfcptv", description: "State Annual Report: HCF Captive " },
        { path: "/annlWstWt", description: "Annual Report Form" },
        { path: "/annlWstStrg", description: "Annual Report Form" },
        { path: "/annlEqp", description: "Annual Report Form" },
        { path: "/annlMisc", description: "Annual Report Form" },
    ]



    useEffectOnce(() => {
          
        let pathName = window.location.pathname;
        console.log(pathName)
        dropdownOne.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })
        dropdownTwo.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })
        dropdownThree.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })

        dropdownFour.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })
        dropdownSix.forEach((res: any) => {
            if (res.path == pathName) {
                setPageTitles(res.description)
            }
        })
        otherpage.forEach((res:any)=>{
            if(res.path == pathName){
                setPageTitles(res.description)
            }
        })

    })

    const [showSideNav, setShowSideNav] = useState(false)

    const toggleSideNavOff = () => {
        setShowSideNav(false)
    }

    const toggleSideNavOn = () => {
        setShowSideNav(true)
    }

    useEffect(()=>{
        console.log('SwitchLayout called');
    },[])
    
    return (
        <>
            {applicationOne === '2' ? <div className={`${showSideNav ? 'grid grid-cols-9' : ""} `}>
                <div className={`${showSideNav ? 'col-span-2' : "hidden"}`}>
                    <div className='bg-white h-screen overflow-y-auto mr-2'>
                        <Sidebar toggleSideNavOff={toggleSideNavOff}  setPageTitle={setPageTitles} dropdownOne={dropdownOne} dropdownTwo={dropdownTwo} dropdownThree={dropdownThree} dropdownFour={dropdownFour} dropdownFive={dropdownFive} dropdownSix={dropdownSix}></Sidebar>
                    </div>
                </div>
                <div className="col-span-7 h-screen overflow-y-auto ml-2">
                    <div className='sticky top-1 z-10 mr-2'>
                        <Topbar toggleSideNavOn={toggleSideNavOn} toggleSideNavOff={toggleSideNavOff} showSideNav={showSideNav} title={pageTitle} />
                    </div>
                    <div className='pb-12'>
                       
                                <div className='mr-2'>
                                    <Outlet />
                                </div>

                    </div>


                </div>
            </div> :
                <div>
                    <Outlet />
                </div>
            }
        </>
    )
}

export default React.memo(SwitchLayout)
