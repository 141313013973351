import { stringify } from "querystring";
import { OrganizeImportsMode } from "typescript";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { useSelector } from "react-redux/es/hooks/useSelector";
import "../styles/common.css"
export const getCmpId = ()=>{
  let cmpid:string = sessionStorage.getItem("cmpid") || "";
  if (!cmpid){
    return ""
  }
  //return base64_decode(cmpid);
  return cmpid;
}

export const GetCmpIdFormState = ()=>{
  const cmpId = useSelector((state:any) => state.login.cmpId);
  return cmpId;
}

export const setCmpId = (data:string)=>{
  data = data? data:"";
  if (data){
   //sessionStorage.setItem("cmpid", base64_encode(data));
    sessionStorage.setItem("cmpid", data);
  }
}

export const getUsrId = ()=>{
  let cmpid:string = sessionStorage.getItem("usrid") || "";
  if (!cmpid){
    return ""
  }
  return cmpid;
}

export const setUsrId = (data:string)=>{
  data = data? data:"";
  if (data){
    sessionStorage.setItem("usrid", data);
  }
  
}

export const getDataFromSession = (name:string)=>{
  let cmpid:string = sessionStorage.getItem(name) || "";
  return base64_decode(cmpid);
}

export const setDataInSession = (data:string, name:string)=>{
  data = data? data:"";
  sessionStorage.setItem(name, base64_encode(data));
}

export const formateWeightToThreeDcml = (ary:any[]) => {
  ary = ary.map((res:any)=>{
    return {...res, bluwt:Number(res.bluwt).toFixed(3), redwt:Number(res.redwt).toFixed(3), ylwwt:Number(res.ylwwt).toFixed(3), whtwt:Number(res.whtwt).toFixed(3), cytwt:Number(res.cytwt).toFixed(3)};
  })
  return ary;
}


const utilities = (Fnct: number | string, parmA: string, parmB: string) => {
  if (Fnct === 1) {
    return ChkExstData(parmA, parmB);
  } else if (Fnct === 2) {
    return GetFldName(parmA);
  } else if (Fnct === 3) {
    return Eguid();
  } else if (Fnct === 4 || Fnct === "GridSource") {
    return GridSrc(parmA);
  } else if (Fnct === 5 || Fnct === "GridCols") {
    return GridCols(parmA);
  } else if (Fnct === 7 || Fnct === "GetAPI") {
    return createGetApi(parmA, parmB);
  } else if (Fnct === 8 || Fnct === "CheckLogin") {
    return chkLgn(parmA);
  }
};

const ChkExstData = (curData: string, fldName: string) => {
  if (curData.indexOf(fldName + "][") > -1) {
    let fldar: string[] = curData.split("=");
    let bln: boolean = false;
    if (fldName.substring(fldName.length - 2) === "id") {
      bln = true;
    }
    for (let i = 0, j = fldar.length; i < j; i++) {
      if (
        fldar[i].indexOf(fldName + "][") == 0 ||
        fldar[i].indexOf("=" + fldName + "][") == 0
      ) {
        fldar[i] = "";
      }
      if (bln) {
        if (
          fldar[i].indexOf(fldName.substring(0, fldName.length - 2) + "][") ===
          0
        ) {
          fldar[i] = "";
        }
      }
    }
    let strFnl: string = "";
    for (let i = 0, j = fldar.length; i < j; i++) {
      if (fldar[i]) {
        if (strFnl.length > 0) {
          strFnl += "=";
        }
        strFnl += fldar[i];
      }
    }
    if (strFnl) {
      strFnl += "=";
    }

    return strFnl;
  }
  return curData;
};

function GetFldName(data: string) {
  let strStr: string = data;
  let fldar: string[] = strStr.split("][");
  return fldar[0];
}

function Eguid() {
  let u: string = "",
    m = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx",
    i = 0,
    rb = (Math.random() * 0xffffffff) | 0;
  while (i++ < 36) {
    var c = m[i - 1],
      r = rb & 0xf,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    u += c === "-" || c === "4" ? c : v.toString(16);
    rb = i % 8 === 0 ? (Math.random() * 0xffffffff) | 0 : rb >> 4;
  }
  u = u.replaceAll("-", "");
  return u;
}

function GridSrc(GridFor: string) {
  if (GridFor === "PageLink") {
    return {
      datafields: [
        { name: "ProductName", type: "string" },
        { name: "QuantityPerUnit", type: "int" },
        { name: "UnitPrice", type: "float" },
        { name: "UnitsInStock", type: "float" },
        { name: "Discontinued", type: "bool" },
      ],
      datatype: "xml",
      id: "ProductID",
      record: "Product",
      root: "Products",
      url: "./../sampledata/products.xml",
    };
  }
}

function GridCols(GridFor: string) {
  if (GridFor === "PageLink") {
    const cellsrenderer = (
      row: number,
      columnfield: string,
      value: string | number,
      defaulthtml: string,
      columnproperties: any,
      rowdata: any
    ): string => {
      return "";
    };
    return [
      {
        text: "Product Name",
        columngroup: "ProductDetails",
        datafield: "ProductName",
        width: 250,
      },
      {
        text: "Quantity per Unit",
        columngroup: "ProductDetails",
        datafield: "QuantityPerUnit",
        cellsalign: "right",
        align: "right",
      },
      {
        text: "Unit Price",
        columngroup: "ProductDetails",
        datafield: "UnitPrice",
        align: "right",
        cellsalign: "right",
        cellsformat: "c2",
      },
      {
        text: "Units In Stock",
        datafield: "UnitsInStock",
        cellsalign: "right",
        cellsrenderer,
        width: 100,
      },
      {
        text: "Discontinued",
        columntype: "checkbox",
        datafield: "Discontinued",
        align: "center",
      },
    ];
  }
}

const sendRequest = async (params: string) => {
  const response = await fetch(params, {
    method: "GET",
  }).then();

  if (response.ok) {
    const body = await response.json();
    if (body) {
      if (body[0]) {
        if (body[0]["Data"]) {
          return body[0]["Data"];
        }
      }
    }
  }
};

function splt_getAtPos(fld: string, sep: string, ps: number) {
  let ech: string[] = fld.split(sep);
  if (ech && ech.length < ps) {
    return "";
  } else {
    if (ech && ech[ps]) {
      return ech[ps];
    } else {
      return "";
    }
  }
  return "";
}

export const createGetApi = (param: string, cndt: string) => {
  let msg: string = "";
  let ech: string[] = param.split("|");
  for (let i = 0, j = ech.length; i < j; i++) {
    if (ech && ech[i].indexOf("db=") === 0) {
      msg += splt_getAtPos(ech[i], "=", 1);
      break;
    }
  }
  msg += "=rowset=fnct=";
  for (let i = 0, j = ech.length; i < j; i++) {
    if (ech && ech[i].indexOf("dll=") === 0) {
      msg += splt_getAtPos(ech[i], "=", 1);
      msg += "=";
      break;
    }
  }

  for (let i = 0, j = ech.length; i < j; i++) {
    if (ech && ech[i].indexOf("fnct=") === 0) {
      msg += splt_getAtPos(ech[i], "=", 1);
      msg += "=";
      break;
    }
  }
  let svdcmp = getCmpId();
  if (!svdcmp) {
    svdcmp = "1";
  }
  msg += svdcmp + "=";
  svdcmp = getUsrId();
  if (!svdcmp) {
    svdcmp = "1";
  }
  msg += svdcmp + "=0=";
  ech = cndt.split("|");

  for (let i = 0, j = ech.length; i < j; i++) {
    if (ech && ech[i]) {
      msg += ech[i];
      msg += "=";
    }
  }
  msg = mdf(msg);
  msg = "https://www.thetaskmate.in/api/GetFldValue/" + msg;
  return msg;
};

export const mdf = (t: string) => {
  t = t.split(" ").join("xz");
  t = t.split(".").join("z2");
  t = t.split("::").join("z3");
  t = t.split(":").join("z4");
  t = t.split("\\").join("z5");
  t = t.split("/").join("z6");
  t = t.split("#").join("z7");
  t = t.split("<").join("L1T");
  t = t.split(">").join("G1T");
  t = t.split("-").join("z8");
  t = t.split("+").join("z9");
  t = t.split("&").join("q1");
  t = t.split("*").join("q2");
  t = t.split("%").join("q3");
  t = t.split("???").join("q4");
  t = t.split("??").join("q5");
  t = t.split("=").join("q6");
  t = t.split("|").join("q7");
  t = t.split("$").join("q8");
  t = t.split("[]").join("q9");
  t = t.split(",").join("x1");
  t = t.split("[").join("x2");
  t = t.split("]").join("x3");
  t = t.split("@").join("x4");
  t = t.split("!").join("qz");
  t = t.split("'").join("`");
  return t;
};

function chkLgn(parm: string) {
  let ech: string[] = parm.split("|");
  //104560|191548|Mayank Hospital|Yogesh|1|Admin||NeerjaAdmin|HospAd NrjLgn.tsx:61
  if (parm.indexOf("[new]") > -1) {
    let vl: string = splt_getAtPos(parm, "[new]", 1);
    let ary: any = dataStr_ToArray(vl);
    if (ary[0]["cmpid"] && ary[0]["cid"] && ary[0]["nm"]) {
      ary[0]["nm"] = ary[0]["nm"].replaceAll("_", " ");
      ary[0]["cmpnm"] = ary[0]["cmpnm"].replaceAll("_", " ");
      setCmpId(ary[0]["cmpid"]);
      setUsrId(ary[0]["cid"]);
      sessionStorage.setItem("cmpnm", ary[0]["cmpnm"]);
      sessionStorage.setItem("dsplnm", ary[0]["nm"]);
      sessionStorage.setItem("app", ary[0]["app"]);
      return "1";
    }
  } else {
    if (ech && ech[0] && ech[1]) {
      if (parseInt(ech[0]) > 0 && parseInt(ech[1]) > 0) {
        ech[2] = ech[2].replaceAll("_", " ");
        ech[3] = ech[3].replaceAll("_", " ");
        setCmpId(ech[0]);
        setUsrId(ech[1]);
        sessionStorage.setItem("cmpnm", ech[2]);
        sessionStorage.setItem("dsplnm", ech[3]);
        let hsp : string = "false"
        if(ech[5].indexOf("Hospital_Aggregrator")>-1){
          const echExtended:string[] = ech[5].split('=');
          hsp = "true"
          sessionStorage.setItem("hospitalid", echExtended[0])
        }
        sessionStorage.setItem("isHospitalAggregator", hsp);
        return "1";
      }
    }
  }

  // sessionStorage.removeItem("cmpid");
  // sessionStorage.removeItem("usrid");
  // sessionStorage.removeItem("cmpnm");
  // sessionStorage.removeItem("dsplnm");
  return "0";
}

export const svLnxSrvr = (
  gid: string,
  fldVl: string,
  mid: string,
  dbcon: string,
  fldFl: string,
  mnId: string,
  tblPos: string | undefined
) => {
  fldVl += "=con][" + dbcon;
  fldVl += "=flnme][" + fldFl;
  if (mid) {
    fldVl += "=mid][" + mid;
  }

  if (mnId) {
    fldVl += "=id][" + mnId;
  }

  if (tblPos) {
    fldVl += "=tblpos][" + tblPos;
  }
  fldVl = mdf(fldVl);
  let msg: string = "vl=" + fldVl;
  msg += "&ctg=32";
  if (gid) {
    msg += "&gid=" + gid;
  } else {
    gid = Eguid();
    gid = gid.replaceAll("-", "");
    msg += "&gid=" + gid;
  }
  msg += "&usr=" + getUsrId();
  msg += "&cmp=" + getCmpId();

  msg = "https://www.amcservice.info/svxmlfgn.php?" + msg;
  return msg;
};

export function dataStr_ToArray(str: string) {
  if (!str) {
    return;
  }

  let ech: string[];
  let sep: string = "";
  if (str.indexOf("$^") > -1) {
    sep = "$^";
  } else if (str.indexOf("$]$") > -1) {
    sep = "$]$";
  } else {
    sep = "$^";
  }

  ech = str.split(sep);
  let Drpdwn = [];
  let rows = str.split("$^");
  for (var i = 0, j = rows.length; i < j; i++) {
    let ech = rows[i].split("=");
    let rw = [];
    for (var z = 0, y = ech.length; z < y; z++) {
      let fld = ech[z].split("][");
      let f: any = fld[0];
      rw[f] = fld[1];
    }
    let r = {};
    Object.assign(r, rw);

    Drpdwn.push(r);
  }

  return Drpdwn;
}

export function convertSv_Grid(data: string) {
  let ech = data.split("=");
  let rw = [];
  for (var z = 0, y = ech.length; z < y; z++) {
    let fld = ech[z].split("][");
    if (fld[0].indexOf("slv") === -1) {
      let f: any = fld[0];
      rw[f] = fld[1];
    }
  }
  let r = {};
  Object.assign(r, rw);
  return r;
}

export function chkReqFlds(fnlStr: string, reqFlds: []) {
  let ech: string[] = fnlStr.split("=");
  let msg: string = "";

  for (let z = 0, y = reqFlds.length; z < y; z++) {
    for (let i = 0, j = ech.length; i < j; i++) {
      let ps = ech[i].indexOf(reqFlds[z]["fld"] + "][");
      if (ps == 0) {
        let str: string = reqFlds[z]["fld"];
        if (ech[i].length - str.length == 2) {
          if (msg) {
            msg += "|";
          }
          msg += reqFlds[z]["msg"];
          break;
        }
      }
    }
  }
  for (let z = 0, y = reqFlds.length; z < y; z++) {
    let ps = fnlStr.indexOf(reqFlds[z]["fld"] + "][");
    if (ps > 0) {
      ps = fnlStr.indexOf("=" + reqFlds[z]["fld"] + "][");
    }
    if (ps == -1) {
      if (msg) {
        msg += "|";
      }
      msg += reqFlds[z]["msg"];
    }
  }
  return msg;
}
export const cmboStr = (
  con: string,
  typr: string,
  allwZero: string,
  fltr: string,
  useMid?: number
) => {
  let cmp: string = getCmpId() || "1";
  //  cmp = "352728";
  let usr: string = getUsrId() || "2";
  let msg: string =
    "https://www.thetaskmate.in/api/getFldvalue/nodb=rowset=fnct=x=a94=" +
    cmp +
    "=" +
    usr +
    "=a=" +
    con +
    "=" +
    typr +
    "=" +
    allwZero;
  if (useMid && useMid > 0) {
    msg += "=" + useMid;
  } else {
    msg += "=0";
  }

  msg += "=" + fltr;

  return msg;
};

export const cmboStr_fnct = (
  con: string,
  dll: string,
  fnctnm: string,
  parms: string
) => {
  ///api/GetFldValue/nodb=rowset=fnct=hospdll=fe=1=1=A=0=
  let cmp: string = getCmpId() || "1";
  let usr: string = getUsrId() || "2";
  let msg: string =
    "https://www.thetaskmate.in/api/getFldvalue/" +
    con +
    "=rowset=fnct=" +
    dll +
    "=" +
    fnctnm +
    "=" +
    cmp +
    "=" +
    usr +
    "=a=" +
    parms;
  return msg;
};

export const CheckIfEdt = (orgData: string, curData: string) => {
  if (orgData === "" || curData === "") {
    return true;
  }
  let orgE: any = orgData.split("=");
  let edtE: any = curData.split("=");
  let blnDif: boolean = false;
  for (let i = 0, j = orgE.length; i < j; i++) {
    let fld: string = orgE[i].split("][");
    for (let z = 0, y = edtE.length; z < y; z++) {
      if (edtE[z].indexOf(fld[0] + "][") === 0) {
        if (orgE[i] === edtE[z]) {
          break;
        } else {
          blnDif = true;
          return blnDif;
        }
      }
    }
  }
  return blnDif;
};

export const CallRoundOff = (amt: number) => {
  let nstr: string = amt.toFixed(2);
  let dc: any = nstr.split(".");
  if (dc && dc.length > 1 && dc[1]) {
    let nm: number = parseInt(dc[1]);

    if (nm > 50) {
      return (100 - nm) / 100;
    } else {
      return -nm / 100;
    }
  } else {
    return 0;
  }

  return 0;
};

export const CheckDataServr = (data: any, fnd: string) => {
  if (data && data.data && data.data[0] && data.data[0][fnd]) {
    return data.data[0][fnd];
  }
  return "";
};
export const cmboStrLrg = (
  con: string,
  typr: string,
  allwZero: string,
  fltr: string,
  useMid?: number
) => {
  let cmp: string = getCmpId() || "1";
  //  cmp = "352728";
  let usr: string = getUsrId() || "2";
  let msg: string =
    "https://www.thetaskmate.in/api/getFldvalue/nodb=rowset=fnct=x=a120=" +
    cmp +
    "=" +
    usr +
    "=a=" +
    con +
    "=" +
    typr +
    "=" +
    allwZero;
  if (useMid && useMid > 0) {
    msg += "=" + useMid;
  } else {
    msg += "=0";
  }

  msg += "=" + fltr;

  return msg;
};





export const RemoveFld = (data : string, fldNm : string)=>{
  if (data){
    let ary : any = data.split("=");
    let i : number = 0 ;
    while (i < ary.length){
      if (ary[i]){
        if (ary[i].indexOf(fldNm + '][')==0){
          ary[i] = "";
        }
      }
      i++;
    }
    i=0;
    data = "";
    while (i < ary.length){
      if (ary[i]){
        if (data){
          data += "="
        }

        data += ary[i];
      }
      i++;
    }
  }
  return data;
}

export const clrFldsExcptDrpDt = (data : string)=>{
  return "";
  let ary : any = data.split("=");
  let i : number = 0 ;
  let skp : boolean = true
  while (i < ary.length){
    skp = true
    if (ary[i]){
      if (ary[i].indexOf("dt_")==0){

      } else if (ary[i].indexOf("tm_")==0){

      }else if (ary[i].indexOf("id][")>-1){
        if (i+1<ary.length){
          let fld : any = ary[i].split("][")
          if (fld && fld[0]){
            fld[0] = fld[0].substring(0, fld[0].length-2);
            if (fld[0]){
              if (ary[i+1].indexOf(fld[0] + '][')==0){

              } else {
                ary[i] = ""
                skp = false
              }
            } else {
              ary[i]=""
              skp = false
            }

          }
        } else {
          ary[i]=""
          skp = false
        }
      } else {
        ary[i] = ""
        skp = false
      }
    }
    if (skp){
      i++
    }
    i++;
  }

  i=0;
    let dt : string = "";
    while (i < ary.length){
      if (ary[i]){
        if (dt){
          dt += "="
        }

        dt += ary[i];
      }
      i++;
    }

    return dt;
}
export const getApiFromBiowaste= (path: string)=>{
  return 'https://biowaste.in/'+path;
  
}

export const getApiFromClinician= (path: string)=>{
  return 'https://api.cliniciankhoj.com/'+path;
  
}

export const getApiFromSwachhtaabhiyan= (path: string)=>{
  return 'https://api.swachhtaabhiyan.in/'+path;
}


export const ChangeCase = (data : any, flds : string) =>{
  let fld : any = {}
  fld =flds.split("#")
  let i : number = 0;
  let ps : number = 0;
  while (i < data.length){
    ps =0;
    while (ps < fld.length){
      if (data[i][fld[ps]]){
        data[i][fld[ps]] = data[i][fld[ps]].toString().toUpperCase()
      }
      ps +=1;
    }

    i+=1;
  }

  return data;

}

export const GetResponseWnds = (data : any)=>{
    if (data && data.data && data.data["Data"]){
      return data.data["Data"];
    }
    if (data && data.data[0] && data.data[0]["Data"]){
      return data.data[0]["Data"];
    }
    if (data && data.data  && data.data["Status"]){
      if (data.data['Status'] == "Success"){
        return ""
      }
      return data.data["Status"];
    }

    if (data && data.data[0] && data.data[0]["Status"]){
      if (data.data[0]['Status'] == "Success"){
        return ""
      }
      return data.data[0]["Status"];
    }
    return "";
}

export const isValidArray = (ary : any) => {
  if (ary && ary[0]){
    return true;
  }
  return false;
}

export const isReqFld = (req : any, fldNm : string) => {
  if (isValidArray(req)){
    if (fldNm){
      let i : number = 0;
      while (i < req.length){
        if (req[i]['fld'] == fldNm){
          return true;
        }
      }
    }
  }

  return false;
}

export const gridAddToolTipColumn=(ary : any, nwCol : string, msg: string, prfxClVl : string, pstFxClVl : string)=>{
  let i : number = 0 ;
  let cnt : number = ary.length;
  let m : string = "";
  while (i < cnt){ 
    m = "";
    if (prfxClVl){
      m = ary[i][prfxClVl] + " "
    }
    m = m + msg;
    if (pstFxClVl){
      m = m + " " + ary[i][pstFxClVl] 
    }
    ary[i][nwCol] = m
    i+=1;
  }

  return ary;
}

const stateAbbreviation: { [key: string]: string }  = {
  "Andhra Pradesh": "AP",
  "Arunachal Pradesh": "AR",
  "Assam": "AS",
  "Bihar": "BR",
  "Chattisgarh": "CG",
  "Goa": "GA",
  "Gujarat": "GJ",
  "Haryana": "HR",
  "Himachal Pradesh": "HP",
  "Jammu and Kashmir": "JK",
  "Jharkhand": "JH",
  "Karnataka": "KA",
  "Kerala": "KL",
  "Madhya Pradesh": "MP",
  "Maharashtra": "MH",
  "Manipur": "MN",
  "Meghalaya": "ML",
  "Mizoram": "MZ",
  "Nagaland": "NL",
  "Odisha": "OD",
  "Punjab": "PB",
  "Rajasthan": "RJ",
  "Sikkim": "SK",
  "Tamil Nadu": "TN",
  "Telangana": "TS",
  "Tripura": "TR",
  "Uttarakhand": "UK",
  "Uttar Pradesh": "UP",
  "West Bengal": "WB",
  "Anadaman and Nicobar Island": "AN",
  "Chandigarh": "CH",
  "Dadra and Nagar Haveli": "DN",
  "Daman and Diu": "DD",
  "Delhi": "DL",
  "Lakshadweep": "LD",
  "Puducherry": "PY"
}

export const getStateAbbreviation = (stateName: string) => {
  const abbreviation = stateAbbreviation[stateName];
  if (abbreviation) {
    return abbreviation;
  } else {
    return "State not found";
  }
};

export const getApplicationVersion = ()=>{
  return process.env.REACT_APP_VERSION ||  '2';
}

const stateFullFormForMap: { [key: string]: string }  = {
"AN":"Andaman & Nicobar Island" ,
"AP":"Andhra Pradesh",
"AR":"Arunachal Pradesh" ,
"AS":"Assam",
"BR":"Bihar" ,
"CH":"Chandigarh",
"CG":"Chhattisgarh",
"DN":"Dadara & Nagar Haveli",
"DD":"Daman & Diu",
"DL":"Delhi",
"GA":"Goa",
"GJ":"Gujarat",
"HR":"Haryana",
"HP":"Himachal Pradesh",
"JK":"Jammu & Kashmir",
"JH":"Jharkhand",
"KA":"Karnataka",
"KL":"Kerala",
"LD":"Lakshadweep",
"MP":"Madhya Pradesh",
"MH":"Maharashtra",
"MN":"Manipur",
"ML":"Meghalaya",
"MZ":"Mizoram",
"NL":"Nagaland",
"OD":"Odisha",
"PY":"Puducherry",
"PB":"Punjab",
"RJ":"Rajasthan",
"SK":"Sikkim",
"TN":"Tamil Nadu",
"TS":"Telangana",
"TR":"Tripura",
"UP":"Uttar Pradesh",
"UK":"Uttarakhand",
"WB":"West Bengal",
}

export const getStateFullForm = (shortForm: any) => {
  const fullForm = stateFullFormForMap[shortForm];
  if (fullForm) {
    return fullForm;
  } else {
    return "State not found";
  }
};

export const showPrint = (data : any) =>{
  let dt : string = GetResponseWnds(data)
  if (dt && dt.indexOf(".pdf")>-1) {
      window.open(dt, "_blank")
  }
}

export const TotalForTable = (arry : any , cols : string)=>{
  let flds : any = cols.split("#")
  let i : number = 0
  let z : number = arry.length
  let totalarry : any = {'lbl':0 , 'nolbl':0}
  let p : number = 0
  let q : number = flds.length
  // while (p <= q)
  // {
  //   totalarry[0][flds[p]] = 0
  //   p += 1
  // }
  while (i < z)
  {
    p=0
    while(p < q)
    {
      totalarry[flds[p]] = Number(totalarry[flds[p]]) + Number(arry[i][flds[p]]) 
      p += 1
    }
    i += 1;
  }
  arry.push(totalarry)
  return arry
}

export default utilities;
